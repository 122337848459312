// @flow
import {
  useDisclosure,
  Button,
  Tab,
  Tabs,
  cn,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import * as React from "react";
import { IoIosAdd } from "react-icons/io";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../../../libs/Notifications";
import { PostPaymentMethodCompaniesIDThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { FormServicesTangible } from "./FormServicesIntangible";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { SiZelle } from "react-icons/si";
import { MdOutlineAccountBalance } from "react-icons/md";
import { FormMethodUSD } from "./FormMethodUSD";
import { set } from "react-hook-form";
type Props = {
  reload?: () => void;
};
export const AddSCPaymentCompany = ({ reload }: Props) => {
  const { item } = useParams();
  const { companies } = useSelector((state: RootState) => state.companyID);
  const [optionMethod, setOptionMethod] = React.useState<string>("1");
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    if (item) {
      let list: any[] = [];
      if (optionMethod === "2") {
        const newPm = {
          ...formData,
          payment_method: 3,
          company_service: Number(item),
          identification: companies?.rif,
          account_number: null,
          phone: null,
        };
        list.push(newPm);
      } else {
        console.log(formData.payment_method.length);
        if (formData.payment_method.length > 0) {
          formData.payment_method?.map((pm: string) => {
            if (pm) {
              const newPm = {
                account_holder_name: formData.account_holder_name,
                account_number: formData.account_number,
                bank: Number(formData.bank),
                email: formData.email,
                phone: "+" + formData.code.concat(formData.phone),
                company_service: Number(item),
                payment_method: pm,
                identification: companies?.rif,
              };
              list.push(newPm);
            }
          });
          ErrorToast("Debe seleccionar al menos un método de pago");
        }
      }
      PostPaymentMethodCompaniesIDThunks(list, item)
        .then((result) => {
          SuccessToast("Servicio configurado correctamente");
          reload && reload();
          onClose();
        })
        .catch(({ response }) => {
          if (response?.status === 400) {
            const errorMessage =
              Object.keys(response.data || {})
                .join(", ")
                .concat(": " + Object.values(response.data || {}).join(", ")) ||
              Object.values(response.data || {}).join(", ") ||
              "Error desconocido";
            return ErrorToast(errorMessage);
          }
        });
      return;
    }
    return ErrorToast("Recargue la página");
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <div className="flex justify-end mx-2">
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Método de Pago
      </Button>
      <ModalComponent
        header={"Configurar Método de Pago"}
        body={
          <>
            <div className="flex flex-col">
              <RadioGroup
                orientation="horizontal"
                value={optionMethod}
                onValueChange={setOptionMethod}
                className="flex justify-center border-2 p-1.5 rounded-full  mx-auto max-w-xl  "
              >
                {tabs.map((tab, i) => (
                  <>
                    <Radio
                    key={i}
                      value={tab.id.toString()}
                      classNames={{
                        base: cn(
                          "inline-flex  items-center  justify-between m-0 hover:opacity-70 gap-4 tap-highlight-transparent space-x-1 peer-checked:bg-red-600",
                          "flex-row-reverse cursor-pointer  rounded-full gap-1 p-1 border-2 border-primary ",
                          "data-[selected=true]:bg-primary  "
                        ),
                      }}
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <tab.Icon className="h-5 w-5" />
                        <span>{tab.label}</span>
                      </div>
                    </Radio>
                  </>
                ))}
              </RadioGroup>
              {tabs.map(
                (tab, i) =>
                  optionMethod == tab.id.toString() && (
                    <div className="flex w-full flex-col items-center space-y-4 p-4  ">
                      <tab.Content onSubmit={handleFormSubmit} ref={formRef} />
                    </div>
                  )
              )}
            </div>
            {/* <Tabs
              fullWidth
              radius="full"
              items={tabs}
              color="primary"
              variant="bordered"
              selectedKey={optionMethod}
              keyboardActivation="automatic"
              onSelectionChange={(key) => {
                // Verifica el tipo de key para asegurarte de que se maneja correctamente
                console.log("Seleccionado:", key);
                setOptionMethod(key.toString()); // Asegúrate de que se está convirtiendo a número si es necesario
              }}
              className="flex justify-center"
            >
              {(item) => (
                <Tab
                  key={item.id}
                  title={
                    <div className="flex justify-center items-center space-x-2">
                      <item.Icon className="h-5 w-5" />
                      <span>{item.label}</span>
                    </div>
                  }
                >
                  {optionMethod == item.id.toString() && (
                    <item.Content onSubmit={handleFormSubmit} ref={formRef} />
                  )}
                </Tab>
              )}
            </Tabs> */}
          </>
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </div>
  );
};

let tabs = [
  {
    id: 1,
    label: "Moneda Nacional",
    Icon: MdOutlineAccountBalance,
    Content: FormServicesTangible,
  },
  {
    id: 2,
    label: "ZELLE",
    Icon: SiZelle,
    Content: FormMethodUSD,
  },
];
