// @flow
import * as React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../store/store";
import {
  Autocomplete,
  AutocompleteItem,
  Checkbox,
  CheckboxGroup,
  cn,
  Input,
} from "@nextui-org/react";
import { BiTransfer } from "react-icons/bi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { keyFilterNumber } from "../../../../../../../helpers/KeyFIlterMovilPay";
import { BanksThunks } from "../../../../../../../store/banks/thunks";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { FormControlLabel } from "@mui/material";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useStoreCodePhones } from "../../../../../../../store/companies/useStoreCodePhone";
import { GetCodePhoneThunks } from "../../../../../../../store/companies/thunks";
import { ErrorToast } from "../../../../../../../libs/Notifications";
import { PaymentParams } from "../../../../../params/payment/payments/paymentParams";
import { schemaSNP } from "./Validators/shemaSNP";
import { error } from "console";

type Props = {
  data?: PaymentMethodsCompany | any;
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormServicesTangible = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
    } = useForm({
      resolver: yupResolver(schemaSNP),
      defaultValues: {
        code: "58",
      },
    });
    const queryClient = useQueryClient();
    const { codePhone, setCodePhone } = useStoreCodePhones((state) => state);
    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    const { banks } = useSelector((d: RootState) => d.banks);
    const dispatch = useDispatch<AppDispatch>();

    const handleCodePhone = () => {
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["codePhone"],
          queryFn: () =>
            GetCodePhoneThunks()
              .then((d) => {
                setCodePhone(d.data);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    };
    const handleBanksParams = () => {
      const param: PaymentParams = new PaymentParams();
      param.work = true;
      dispatch(BanksThunks(param));
    };
    React.useEffect(() => {
      handleBanksParams();
      handleCodePhone();
    }, []);
    React.useEffect(() => {
      handleBanksParams();
      handleCodePhone();
    }, []);
    React.useEffect(() => {
      setValue("bank", data?.bank.toString());
      setValue("payment_method", [data?.payment_method.toString()]);
      setValue("account_number", data?.account_number);
      setValue("account_holder_name", data?.account_holder_name);
      setValue("phone", data?.phone.slice(3));
      setValue("email", data?.email);
    }, [data]);
    React.useEffect(() => {
      console.log(errors);
    }, [errors]);
    return (
      <>
        <div className="flex flex-col my-4 space-y-2 ">
          {data ? (
            <>
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                {data?.payment_method_name}
              </div>
            </>
          ) : (
            <>
              <Controller
                name="payment_method"
                control={control}
                render={({ field }) => (
                  <div className="grid justify-center ">
                    <CheckboxGroup
                      {...field}
                      orientation="horizontal"
                      classNames={{
                        base: "w-full ",
                      }}
                      onChange={(event) => field.onChange(event)}
                      isInvalid={!!errors.payment_method}
                      errorMessage={errors.payment_method?.message}
                    >
                      {tabs.map((option) => (
                        <div key={option.id}>
                          <FormControlLabel
                            value={option.id}
                            control={
                              <Checkbox
                                classNames={{
                                  base: cn(
                                    "w-full m-0 bg-content2 hover:bg-content2 items-center font-medium",
                                    "flex-row-reverse cursor-pointer rounded-full gap-2 p-2 border-2 border-transparent text-primary",
                                    "data-[selected=true]:border-primary data-[selected=false]:border-white "
                                  ),
                                }}
                                color="primary"
                                isInvalid={!!errors.payment_method}
                              >
                                <div className="flex justify-center items-center space-x-2">
                                  <option.Icon className="h-5 w-5" />
                                  <span>{option.label}</span>
                                </div>
                              </Checkbox>
                            }
                            className="cursor-pointer"
                            label=""
                          />
                        </div>
                      ))}
                    </CheckboxGroup>
                  </div>
                )}
              />
            </>
          )}
          <Controller
            name="bank"
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center">
                  <Autocomplete
                    {...field} // Pasa las propiedades del Controller
                    className="max-w"
                    variant="bordered"
                    defaultItems={banks}
                    placeholder="Seleccione el banco a afiliar"
                    isInvalid={!!errors.bank}
                    errorMessage={errors.bank?.message}
                    selectedKey={field.value}
                    onSelectionChange={(e) => field.onChange(e)}
                  >
                    {(item) => (
                      <AutocompleteItem key={item.id}>
                        {item.name + " /  " + item.code}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                </div>
              </>
            )}
          />
          <div className="flex gap-2">
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center">
                    <Autocomplete
                      {...field} // Pasa las propiedades del Controller
                      className="max-w"
                      variant="bordered"
                      defaultItems={codePhone}
                      placeholder="Codigo"
                      isInvalid={!!errors.code}
                      errorMessage={errors.code?.message}
                      selectedKey={field.value}
                      onSelectionChange={(e) => field.onChange(e)}
                    >
                      {(item) => (
                        <AutocompleteItem key={item.phone_code}>
                          {item.phone_code + "/" + item.iso3_code}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  </div>
                </>
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    type="text"
                    placeholder="Telefono"
                    variant="bordered"
                    maxLength={10}
                    isInvalid={!!errors.phone}
                    errorMessage={errors.phone?.message}
                  />
                  <span></span>
                </>
              )}
            />
          </div>
          <Controller
            name="account_number"
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  placeholder="Nro. Cuenta"
                  variant="bordered"
                  maxLength={24}
                  isInvalid={!!errors.account_number}
                  errorMessage={errors.account_number?.message}
                />
                <span></span>
              </>
            )}
          />
          <Controller
            name="account_holder_name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Input
                  type="text"
                  maxLength={30}
                  onChange={onChange}
                  variant="bordered"
                  isInvalid={!!errors.account_holder_name}
                  errorMessage={errors.account_holder_name?.message}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Colocar el nombre del Titular "
                />
                <span></span>
              </>
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Input
                  maxLength={30}
                  onChange={onChange}
                  variant="bordered"
                  isInvalid={!!errors.email}
                  errorMessage={errors.email?.message}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Colocar el correo"
                />
                <span></span>
              </>
            )}
          />
        </div>
      </>
    );
  }
);

let tabs = [
  {
    id: 1,
    label: "Pago móvil",
    Icon: HiOutlineDevicePhoneMobile,
  },
  {
    id: 2,
    label: "Transferencia",
    Icon: BiTransfer,
  },
];
let optionMethod = ["P2P", "P2C"];
