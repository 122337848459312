import * as yup from "yup";
export const schemaCompany = yup.object({
  name: yup.string().required("Este campo es requerido"),
  legal_name: yup.string().required("Este campo es requerido"),

  website: yup.string().url("Debe contener el formarto HTTP"),
  description: yup
    .string()
    .required("Este campo es requerido")
    .max(250, "La descripción no puede superar los 250 caracteres"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("debe contener el formarto de correo"),

  phone: yup
    .string()
    .required("Este campo es requerido")
    .max(10, "El teléfono tiene un máximo de 10 caracteres")
    .matches(/^[0-9]{10}$/, "Formato XXXXXXXXXXX"),
  whatsapp_phone: yup
    .string()
    .required("Este campo es requerido")
    .max(10, "El teléfono tiene un máximo de 10 caracteres")
    .matches(/^[0-9]{10}$/, "Formato XXXXXXXXXXX"),

  billing_address: yup.string().required("Este campo es requerido"),
  location_state: yup.number().required("Este campo es requerido"),
  location_parroquia: yup.number().required("Este campo es requerido"),
  location_sector: yup.number().required("Este campo es requerido"),
  type: yup.number().required("Requerido"),
  ocupation: yup.number().required("Requerido"),
  economic_sector: yup.number().required("Requerido"),
  withholding_agent: yup.string().required("Requerido"),
  code: yup.string().required("Debes selecionar el codigo"),
  codeWhats: yup.string().required("Debes selecionar el codigo"),
  // date_incorporation: yup.date().required("Este campo es requerido"),
  // file_rif: yup.string().required("Este campo es requerido"),
  // file_act: yup.string().required("Este campo es requerido"),
});
export const schemaCompanyShort = yup.object({
  rif: yup
    .string()
    .max(10, "Hasta 10 caracteres")
    .matches(/^[0-9]*$/, "Soló números")
    .required("Este campo es requerido"),
  type: yup.string().required("Este campo es requerido"),
});
