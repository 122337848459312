// @flow
import * as React from "react";
import {
  Menu,
  usePermissionsMenuMovilPay,
} from "../../../../hooks/useUserMovilPay";
import { Accordion, AccordionItem, menu, MenuItem } from "@nextui-org/react";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { ErrorToast } from "../../../../libs/Notifications";
import { GetPermissionsThunks } from "../../../../store/permission/thunks";
import { UserParams } from "../../params/settings/userParams";
import { useStorePermissions } from "../../../../store/permission/useStorePermission";
import { NavLink } from "react-router-dom";
import Icons from "../icons";
import { useState } from "react";
import { IoGrid } from "react-icons/io5";
import { FaCircle } from "react-icons/fa";
import { getCookie, setCookie } from "../../../../config/cookies";
type Props = {
  menu: Menu;
  isExpand: boolean;
};
export const MenuItems: React.FC<{ menu: Menu; isExpand: boolean }> = ({
  menu,
  isExpand,
}: Props) => {
  const { permissions, setPermissions } = useStorePermissions((state) => state);
  const queryClient = useQueryClient();

  const fetchPermissions = () => {
    const param = new UserParams();
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["permissions"],
        queryFn: () =>
          GetPermissionsThunks(param, menu.id)
            .then((d) => {
              setPermissions(d.data);
              setCookie("menuPermissions", d.data);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  React.useEffect(() => {
    const storedPermissions = getCookie("menuPermissions") ?? "[]";
    if (storedPermissions) {
      setPermissions(storedPermissions);
    }
  }, []);
  return (
    <div>
      {menu.children && menu.children?.length > 0 ? (
        <>
          <Accordion selectionMode="multiple" isCompact>
            <AccordionItem
              onPress={fetchPermissions}
              key={menu.path}
              startContent={
                menu.icon && Icons[menu.icon as keyof typeof Icons] ? (
                  React.createElement(Icons[menu.icon as keyof typeof Icons], {
                    className: "h-6 w-6 shrink-0",
                    "aria-hidden": "true",
                  })
                ) : (
                  <IoGrid className="h-6 w-6 shrink-0" aria-hidden="true" />
                ) // Retorna  si no hay ícono
              }
              title={
                <p
                  className={
                    !isExpand
                      ? "hidden"
                      : `text-dark  dark:text-white group flex  rounded-md  text-base leading-6 font-semibold`
                  }
                >
                  {menu.name}
                </p>
              }
            >
              {menu.children && menu.children.length > 0 && (
                <div style={{ paddingLeft: "20px" }}>
                  {menu.children.map((child) => (
                    <MenuItems
                      key={child.id}
                      menu={child}
                      isExpand={isExpand}
                    />
                  ))}
                </div>
              )}
            </AccordionItem>
          </Accordion>
        </>
      ) : (
        <div onClick={fetchPermissions} style={{ cursor: "pointer" }}>
          <NavLink
            to={menu.path}
            className={({ isActive }) => {
              const res = isActive
                ? "bg-secondary text-white shadow-lg"
                : " hover:text-white hover:bg-secondary dark:text-white";
              return `${res} group flex ${
                !isExpand && "justify-center  animate-jump-in"
              } items-center  gap-x-3 rounded-md p-2 text-base leading-6 font-semibold duration-200`;
            }}
          >
            {/* Verifica que el ícono existe en el objeto Icons */}
            {
              menu.parent == null &&
              menu.icon &&
              Icons[menu.icon as keyof typeof Icons] ? (
                React.createElement(Icons[menu.icon as keyof typeof Icons], {
                  className: "h-6 w-6 shrink-0",
                  "aria-hidden": "true",
                })
              ) : (
                <FaCircle className="h-2 w-2 shrink-0" aria-hidden="true" />
              ) // Retorna  si no hay ícono
            }
            <span className={!isExpand ? "hidden" : ""}>{menu.name}</span>
          </NavLink>
        </div>
      )}
    </div>
  );
};
