import { useDisclosure, Button } from "@nextui-org/react";
import { useRef } from "react";
import { LuFileEdit } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { ErrorToast,  SuccessToast } from "../../../../../../libs/Notifications";
import { DeleteDeviceThunks, UpdateDeviceThunks } from "../../../../../../store/managements/devices/thunks";
import { AppDispatch } from "../../../../../../store/store";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormDevices } from "./FormDevices";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { FaRegTrashCan } from "react-icons/fa6";


type Props = {
    data?: any
    reload: () => void
  };

export const Action = ({ data, reload }: Props) => {

    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();


    const dispatch: AppDispatch = useDispatch();
    const formRef = useRef<() => void | null>(null);





    const handleFormSubmit = (formData: any) => {
        console.log("update data", formData);
        const body = {
            "name": formData.name,
            "detail": formData.description,
            "service": formData.service,
            "cost": formData.price
        }
        UpdateDeviceThunks(body, data.id)
            .then((result) => {
                SuccessToast("Dispositivo actualizado correctamente");
                onClose();
                reload();

            })
            .catch(({ response }) => {
                if (response?.status === 400) {
                    const { name, description } = response?.data;
                    return ErrorToast(description || name);
                }
                ErrorToast("Hubo un error al actualizar el Dispositivo");
                return;
            });
    };

    const handleSubmitClick = () => {
        if (formRef.current) {
            formRef.current();
        }
    };

    return (
        <>
            <Button
                isIconOnly
                variant="light"
                color="primary"
                size="sm"
                onPress={onOpen}
            >
                <LuFileEdit  className={`font-semibold h-6 w-6 `} />
            </Button>
            <ModalComponent
                header={`Editar datos del dispositivo ${data.name}`}
                body={<FormDevices data={data} onSubmit={handleFormSubmit} ref={formRef} />}
                handleSubmitClick={handleSubmitClick}
                onClose={onClose}
                isOpen={isOpen}
                onOpen={onOpen}
                onOpenChange={onOpenChange}
                title={""}
            />
        </>
    );
};

const FooterModalComponent = (onSubmit: () => void, onClose: () => void): JSX.Element | React.ReactNode => {
    return (
        <div className="flex justify-end gap-x-2">
            <Button
                size="sm"
                type="button"
                onClick={onClose}
                color="danger"
                className="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold sm:mt-0 sm:w-auto"
            >
                Cancelar
            </Button>
            <Button
                size="sm"
                type="submit"
                onClick={onSubmit}
                color="primary"
                className="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold sm:mt-0 sm:w-auto"
            >
                Guardar
            </Button>
        </div>
    );
};

export const ActionDelete = ({data,reload}:Props) => {
    const dispatch: AppDispatch = useDispatch();

    const handleDelete = () => {


        DeleteDeviceThunks(data.id)
            .then((result) => {
                SuccessToast("Dispositivo eliminado correctamente");
                reload();
            })
            .catch(({ response }) => {
                if (response?.status === 400) {
                    // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                    const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
                    return ErrorToast(errorMessage);
                  }
                ErrorToast("Hubo un error al eliminar el Dispositivo");
                return;
            });

    };

    return (
        <PopoverConfirm
            title="Confirmar Eliminación"
            message="¿Estás seguro de que deseas eliminar este elemento?"
            confirm={handleDelete} // Esta función se ejecutará al confirmar
            item={undefined}    >
            <Button isIconOnly variant="light" color="danger" size="sm">
                <FaRegTrashCan  className="font-semibold h-6 w-6" />
            </Button>
        </PopoverConfirm>
    );

}