export class ServicesConfigParams {
  company?: string;
  company_id?: string;
  is_active?:string | boolean;
  search?: string;
  service?: string;
  remove_pagination?: string;
  page?: number;
  finish?: string;
  status?: number ;
}
