
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { create } from "zustand";
import { Payment } from "../../../../interfaces/PaymentInterfaces";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useUserMovilPay } from "../../../../../../hooks/useUserMovilPay";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";
import { getPaymentRequest, PaymentsThunks } from "../../../../../../store/payment/thunks";
import { useEffect } from "react";
import { CardInfoPayments } from "../components/CardInfoPayments";
import { BottonsPayments } from "../../../finances/payments/components/table/BottonsPayments";
import { CheckFilterPayments } from "../../../finances/payments/components/filters/CheckFilterPayments";
import { TemplateTableLayout } from "../../../../layout/TemplateTableLayout";
import { BanksDestinyOriginPayments } from "../../../finances/payments/components/filters/BanksDestinyOriginPayments";
import { SinceUntilPayments } from "../../../finances/payments/components/filters/SinceUntilPayments";
import { TablePayment } from "../../../finances/payments/components/table/TablePayments";
import PaymentCore from "../../../finances/payments/PaymentCore";
import { useStorePaymentCore } from "../../../../../../store/payment/useStorePayment";
import { ErrorToast } from "../../../../../../libs/Notifications";

export const PaymentsCompanyCore = () => { 
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { count, setPayments, payments, resetPayments } = useStorePaymentCore(
    (state: any) => state
  );
  const { permissions,  } = usePermissions();
  const actionPermissionFilter =permissions && permissions.includes('action_filter_payments');
  const actionPermissionAdd =permissions && permissions.includes('action_add_payments');
  const { id, item, serviceId } = useParams();
  const { company } = useUserMovilPay();
  const handleConsultation = async () => {
    const option = id ? id : company;
    const param = new PaymentParams();
    param.company = Number(option);
    param.payment_method_cs = serviceId;
    params.method && (param.method = params.method);
    params.status && (param.status = params.status);
    params.page && (param.page = Number(params.page));
    params.since && (param.since = params.since);
    params.until && (param.until = params.until);
    params.search && (param.search = params.search);
    params.bank_origin && (param.bank_origin = params.bank_origin);
    params.bank_destiny && (param.bank_destiny = params.bank_destiny);
    params.device && (param.device = params.device);
    await queryClient.fetchQuery(
      queryOptions({
        queryKey: ["paymentsHistory"],
        queryFn: () =>
          getPaymentRequest(param)
            .then((d) => {
              setPayments(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };

  useEffect(() => {
     handleConsultation();
  }, [params]);

  const handleSearch = ({ search }: any) => {
    if (search == undefined || search == null) return;

    if (search.length == 0) {
      deleteParams(["search"]);
      return;
    }
    const { page, ...rest } = params;
    setSearchParams({ ...rest, search });
  };
 
  const handleDelete = () => {
    deleteParams(["since", "until", "page", "method", "search", "bank_origin", "bank_destiny"  ]);
    handleConsultation();
  };
  return (
    <div className="animate-fade-up space-y-5">
      <CardInfoPayments />
      <TemplateTableLayout
        title="Información de los pagos"
        bottons={actionPermissionAdd && <BottonsPayments refresh={handleConsultation} />}
        search={handleSearch}
        deleteParams={handleDelete}
        filters={actionPermissionFilter ?[
          {
            name: "",
            component: <CheckFilterPayments />,
            field: "status",
          },
          {
            name: "Introduce un rango de fecha",
            component: <SinceUntilPayments />,
            field: "since",
          },
          {
            name: "Selecciona los bancos",
            component: <BanksDestinyOriginPayments />,
            field: "banks",
          },
         
        ]:[]}
      >
        <>
          {params.since && (
            <p className="font-semibold text-xl dark:text-white text-end mt-4">
              {params.since && params.since}
              {params.until && ` – ${params.until}`}
            </p>
          )}
        </>
      <TablePayment />
      </TemplateTableLayout>
    </div>
  );
};

export default PaymentCore;
