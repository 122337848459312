// @flow
import * as React from "react";
import { DevicesServicesConfigParams } from "../../../../../params/services-configs/DevicesServicesConfig";
import { Link, useParams } from "react-router-dom";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { IoBanOutline } from "react-icons/io5";
import { TableSCDevice } from "./table/TableSCDevice";
import { useStoreDevicesServiceCompany } from "../../../../../../../store/companies/DevicesCompany/useStoreDevicesServiceCompany";
import { GetDevicesServiceCompanyThunks } from "../../../../../../../store/companies/DevicesCompany/thunks";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { FcSearch } from "react-icons/fc";
import { AddSCDevice } from "./AddSCDevice";
import { ErrorToast } from "../../../../../../../libs/Notifications";
import { FaArrowLeft } from "react-icons/fa";
import { GetPaymentMethodCompaniesThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import { useStoreSCPaymentMethodCore } from "../../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { ServicesConfigParams } from "../../../../../params/services-configs/ServicesConfigParams";
import { MethodPaymentCompany } from "../../../../../interfaces/PaymentInterfaces";
import { GetServicesCompaniesThunks } from "../../../../../../../store/companies/servicesCompany/thunks";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";
import { BiSearch } from "react-icons/bi";
type Props = {
  reload?: () => void;
};

export const ConfigServicesDevices = () => {
  const queryClient = useQueryClient();
  const { company } = useUserMovilPay();

  const { id } = useParams();
  const { count, setDevicesServiceCompany } = useStoreDevicesServiceCompany(
    (state) => state
  );
  const { serviceCompany, setServiceCompany } = useStoreServiceCompanyCore(
    (state) => state
  );
  const { scpaymentMethod, setscpaymentMethod, reset } =
    useStoreSCPaymentMethodCore((state) => state);

  const { params, deleteParams, setSearchParams } = useAllParams();
  const { control, handleSubmit, getValues, formState } = useForm({
    defaultValues: {
      search: params.search || "",
      status: params.status || "",
      payment_method_cs: params.payment_method_cs || "",
      service: params.service || "",
    },
  });
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_device_to_company");

  // Get Devices and Params
  const handleConsultation =  () => {
    const option = id ? id : company;
    const param = new DevicesServicesConfigParams();
    param.company = option;
    if (params.search) param.search = params.search;
    if (params.status) param.status = params.status;
    if (params.service) param.service = params.service;
    if (params.payment_method_cs)
      param.payment_method_cs = params.payment_method_cs;
    try {
    queryClient.fetchQuery(
        queryOptions({
          queryKey: ["scdevicesAll"],
          queryFn: () =>
            GetDevicesServiceCompanyThunks(param)
              .then((d) => {
                setDevicesServiceCompany(d.data.results, d.data.count);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    } catch (error) {
      console.error(error, "devices");
    }
  };
  // Get services company
  const handleSC = () => {
    const param = new ServicesConfigParams();
    const option = id ? id : company;
    param.is_active = true;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["serviceCompanysC"],
        queryFn: () =>
          GetServicesCompaniesThunks(param, option)
            .then((d) => {
              setServiceCompany(d.data, d.data.length);
            })
            .catch((err) => console.error(err)),
        retry: false,
      })
    );
  };
  // Get method payment
  const handleSCPM = (serv: number) => {
    const param = new ServicesConfigParams();
    param.status = 1;
    if (serv !== 0) {
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["scpaymentInit"],
          queryFn: () =>
            GetPaymentMethodCompaniesThunks(param, serv).then((d) => {
              const is_pm = d.data.filter(
                (item: MethodPaymentCompany) => item.payment_method === 1
              );
              setscpaymentMethod(is_pm, d.data.length);
            }),
          retry: false,
        })
      );
    }
  };

  React.useEffect(() => {
    handleSC();
  }, []);
  React.useEffect(() => {
    handleConsultation();
  }, [params, id]);

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };
  // Params Payment  Method
  const handlePayMethSC = (e: any) => {
    const payment_method_cs: any = e;
    if (payment_method_cs == "") {
      deleteParams(["payment_method_cs"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, payment_method_cs: payment_method_cs });
    }
  };
  // Params Status
  const handleStatus = (e: any) => {
    const status: any = e;
    if (status == "") {
      deleteParams(["status"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, status: status });
    }
  };
  //Params Service
  const handleServices = (e: any) => {
    reset();
    const service: any = e;
    if (service == "") {
      deleteParams(["service"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, service: service });
      const filterId = serviceCompany.filter(
        (sc) => sc.service === Number(service)
      );
      handleSCPM(filterId[0]?.id);
    }
  };

  return (
    <>
      <div className="my-4 flex justify-between">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full  gap-x-2"
        >
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <div className="flex w-full  items-center">
                <Input
                  {...field}
                  type="text"
                  variant="bordered"
                  placeholder="Introduce para buscar"
                />
                <Button
                  isIconOnly
                  color="primary"
                  type="submit"
                  className="ml-2"
                  size="sm"
                >
                  <BiSearch className="h-6 w-6 text-white" />
                </Button>
              </div>
            )}
          />
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <Select
                {...field}
                items={statusData}
                placeholder="Filtrar por estado"
                className="max-w-xs"
                variant="bordered"
                key={field.value}
                onChange={(e) => handleStatus(e.target.value)}
              >
                {(status) => (
                  <SelectItem key={status.value}>{status.label}</SelectItem>
                )}
              </Select>
            )}
          />
          <Controller
            control={control}
            name="service"
            render={({ field }) => (
              <Select
                {...field}
                items={serviceCompany}
                placeholder="Filtrar por servicios"
                className="max-w-xs"
                variant="bordered"
                key={field.value}
                onChange={(e) => {
                  handleServices(e.target.value);
                }}
              >
                {(service) => (
                  <SelectItem key={service.service} value={service.id}>
                    {service.service_name}
                  </SelectItem>
                )}
              </Select>
            )}
          />
          <Controller
            control={control}
            name="payment_method_cs"
            render={({ field }) => (
              <Select
                {...field}
                items={scpaymentMethod}
                placeholder="Filtrar por cuenta bancaria"
                className="max-w-xs"
                variant="bordered"
                key={field.value}
                onChange={(e) => handlePayMethSC(e.target.value)}
              >
                {(status) => (
                  <SelectItem key={status.id}>
                    {status.bank_achronym + " / " + status.account_number}
                  </SelectItem>
                )}
              </Select>
            )}
          />
        </form>
        {actionPermissionAdd && <AddSCDevice reload={handleConsultation} />}
      </div>
      <div>
        {count > 0 ? (
          <>
            <TableSCDevice reload={handleConsultation} />
          </>
        ) : (
          <div className="text-center">
            <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-xl font-semibold text-gray-500">
              No hay resultado
            </h3>
          </div>
        )}
      </div>
    </>
  );
};
const statusData = [
  { label: "Todos", value: "" },
  { label: "Activos", value: "true" },
  { label: "Inactivos", value: "false" },
];
