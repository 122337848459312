// @flow
import * as React from "react";
import { ServicesCompany } from "../../create/components/ServicesCompany";
import { Button, useDisclosure } from "@nextui-org/react";
import { IoIosAdd } from "react-icons/io";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Services } from "../../../../interfaces/ServicesInterfaces";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorToast, LoadingToast, SuccessToast } from "../../../../../../libs/Notifications";
import { PostServiceConfigThunks } from "../../../../../../store/companies/thunks";
import { ServicesThunks } from "../../../../../../store/services/thunks";
import { PLayouts } from "../../../../layout/PLayouts";
import { CustomCheckbox } from "./SelectedServices";
import { PostServicesCompaniesThunks } from "../../../../../../store/companies/servicesCompany/thunks";
import { useParams } from "react-router-dom";
import { useUserMovilPay } from "../../../../../../hooks/useUserMovilPay";
type Props = {
  reload: () => void;
  onClose?: () => void;
};

interface FormValues {
  options: { [key: string]: any };
}
export function AddServices({ reload }: Props) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { companies } = useSelector((state: RootState) => state.companyID);

  React.useEffect(() => {}, []);

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Crear Servicio
      </Button>
      <ModalComponent
        header={"Agregar nuevo servicio"}
        body={<BodyModalComponent reload={reload} onClose={onClose} />}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
        isOpen={isOpen}
      />
    </>
  );
}

const BodyModalComponent = ({ onClose, reload }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedServices, setSelectedServices] = React.useState<Services[]>(
    []
  );
  const { id } = useParams();
  const { company} = useUserMovilPay();
  const { companies } = useSelector((state: RootState) => state.companyID);
  const { services, count } = useSelector((state: RootState) => state.services);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      options: services?.reduce(
        (acc, service) => ({ ...acc, [service.id]: service }),
        {}
      ),
    },
  });
  // Manejo del envío del formulario
  const onSubmit: SubmitHandler<FormValues> = () => {
    const option = id ? id : company
    const arr: any[] = []; 
    selectedServices.map((service) => arr.push({service: service.id}));
    PostServicesCompaniesThunks(arr, option)
      .then(() => {
        onClose && onClose();
        setSelectedServices([]);
        reload();
        SuccessToast(
          "Datos de la compañia agregados exitosamente! Seguir con el registro."
        );
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  const handleCheck = (service: Services, checked: boolean) => {
    setSelectedServices((prevSelectedServices: any[]) => {
      if (checked) {
        // Add service to selectedServices if checked
        return [...prevSelectedServices, service];
      } else {
        // Remove service from selectedServices if unchecked
        return prevSelectedServices.filter((s) => s.id !== service.id);
      }
    });
  };

  // Actualizamos el estado de los servicios seleccionados cuando cambian los valores
  React.useEffect(() => {
    setSelectedServices([]);
    dispatch(ServicesThunks());
  }, []);
  return (
    <>
      <div className="grid space-y-4  justify-center w-full">
        <div className="flex justify-between items-center pt-4">
          <PLayouts message="Seleccion los servicos " />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="grid space-y-4">
          <CustomCheckbox
            items={count > 0 ? services : []}
            name="options"
            control={control}
            fnCheck={handleCheck}
          />
          <div className="flex justify-center gap-x-2 my-6">
            {selectedServices && selectedServices.length > 0 && (
              <Button
                color="primary"
                size="md"
                type="submit"
                className=" text-white text-base shadow-lg font-semibold  rounded-lg active:scale-95 transition-transform transform"
              >
                Guardar
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
