import { Button, ScrollShadow, Tooltip } from "@nextui-org/react";
import { configTaiwind } from "../../../../../../utils/configTaiwind";
import { FaBuildingUser } from "react-icons/fa6";

import { FaInfo } from "react-icons/fa";
import { IoBanOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { useNavigate } from "react-router-dom";
import { CardCompany } from "../../create/components/CardCompany";
import { CardCompanies } from "./CardCompanies";

export const ResultsSearchCompanies = () => {
  const { companies } = useSelector((d: RootState) => d.companies);
  const navigate = useNavigate();

  const handleButton = (id: number) => {
    // addParams({ contract: id });
    navigate(`./company/${id}/`);
  };
  return (
    <>
      <div
        role="list"
        className=" "
      >
        {companies.length === 0 ? (
          <ResultEmptyCompanies />
        ) : (
          <ScrollShadow
            className={`grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 py-4 gap-4  ${configTaiwind.scroll}`}
          >
            {companies.map((company) => (
              <CardCompanies
                key={company.id}
                company={company}
                handleButton={handleButton}
              />
              // <li
              //   key={company.id}
              //   className={`${configTaiwind.animateView} relative flex justify-between  gap-x-1 py-5 px-3`}
              // >
              //   <div className="flex min-w-0 gap-x-4 items-center">
              //     <FaBuildingUser className="h-12 w-12 rounded-full p-2 flex-none text-primaryDark  bg-gray-200" />
              //     <div className="min-w-0 flex-auto">
              //       <p className="text-lg font-semibold leading-6 text-gray-900 dark:text-white">
              //         {company.name}
              //       </p>
              //       <p className="text-md font-medium leading-6 text-gray-700 dark:text-white">
              //         {company.email}
              //       </p>
              //     </div>
              //   </div>
              //   <div className="flex shrink-0 items-center gap-x-4">
              //     <div>
              //       <Tooltip
              //         content="Ver información de compañia"
              //         className="bg-default text-white"
              //       >
              //         <Button
              //           isIconOnly
              //           color="primary"
              //           variant="ghost"
              //           size="sm"
              //           onClick={() => handleButton(company.id)}
              //         >
              //           <FaInfo
              //             className="h-4 w-4 flex-none"
              //             aria-hidden="true"
              //           />
              //         </Button>
              //       </Tooltip>
              //     </div>
              //   </div>
              // </li>
            ))}
          </ScrollShadow>
        )}
      </div>
    </>
  );
};

const ResultEmptyCompanies = () => {
  return (
    <li
      className={`${configTaiwind.animateView} relative flex justify-center gap-x-1 py-5 px-3  `}
    >
      <div className="text-center">
        <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-xl font-semibold text-gray-500">
          No hay resultado
        </h3>
      </div>
    </li>
  );
};
