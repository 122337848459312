// @flow
import { FaArrowLeft } from "react-icons/fa";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { TablePayment } from "../../../finances/payments/components/table/TablePayments";
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import {
  getPaymentRequest,
  PaymentsThunks,
} from "../../../../../../store/payment/thunks";
import { useEffect, useState } from "react";
import { TemplateTableLayout } from "../../../../layout/TemplateTableLayout";
import { BanksDestinyOriginPayments } from "../../../finances/payments/components/filters/BanksDestinyOriginPayments";
import { CheckFilterPayments } from "../../../finances/payments/components/filters/CheckFilterPayments";
import { SinceUntilPayments } from "../../../finances/payments/components/filters/SinceUntilPayments";
import { Button } from "@nextui-org/react";
import { HiArrowPath } from "react-icons/hi2";
import { DevicesServicesConfigParams } from "../../../../params/services-configs/DevicesServicesConfig";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { ErrorToast } from "../../../../../../libs/Notifications";
import { GetDevicesServiceCompanyThunks } from "../../../../../../store/companies/DevicesCompany/thunks";
import { useStoreDevicesServiceCompany } from "../../../../../../store/companies/DevicesCompany/useStoreDevicesServiceCompany";
import { FilterForSCPaymentMethod } from "./FilterForSCPaymentMethod";
import { GetIdPaymentMethodCompaniesThunks } from "../../../../../../store/companies/PaymentMethodCompany/thunks";
import { useUserMovilPay } from "../../../../../../hooks/useUserMovilPay";
import { ValidatePayment } from "./ValidatePayment";
import { useStorePaymentCore } from "../../../../../../store/payment/useStorePayment";
import { useStoreSCPaymentMethodCore } from "../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { IoBanOutline } from "react-icons/io5";
type Props = {};
export const HistoryServicesTransactions = (props: Props) => {
  const { params, deleteParams, setSearchParams, addParams } = useAllParams();
  const { id, item, serviceId } = useParams();
  const { company } = useUserMovilPay();
  const queryClient = useQueryClient();
  const { devicesServiceCompany, setDevicesServiceCompany } =
    useStoreDevicesServiceCompany((state) => state);

  const { count, setPayments } = useStorePaymentCore((state: any) => state);
  const [bankDestiny, setBankDestiny] = useState("");
  const [methodParam, setMethodParam] = useState("");
  const [companyID, setCompanyId] = useState<number | string>();
  const navigate = useNavigate();

  const handleDevicesSCPM = () => {
    const param = new DevicesServicesConfigParams();
    param.payment_method_cs = serviceId;
    param.remove_pagination = "true";
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["scdevices"],
        queryFn: () =>
          GetDevicesServiceCompanyThunks(param)
            .then((d) => {
              setDevicesServiceCompany(d.data, d.data.length);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleGetIDPaymentMethod = () => {
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["IDPaymentMethod"],
        queryFn: () =>
          GetIdPaymentMethodCompaniesThunks(Number(serviceId))
            .then((d) => {
              const { payment_method, bank } = d.data;
              const idCom = id ? id : company
              setMethodParam(payment_method);
              setBankDestiny(bank);
              setCompanyId(idCom);
              addParams({
                company: idCom,
                method: payment_method,
                bank_destiny: bank,
              });
              payment_method === 1 && handleDevicesSCPM();
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  useEffect(() => {
    handleGetIDPaymentMethod();
  }, []);
  const handleConsultation = async () => {
    const param = new PaymentParams();
    param.payment_method_cs = serviceId;
    param.company = companyID || params.company;
    params.method && (param.method = methodParam);
    params.bank_destiny  && (param.bank_origin = bankDestiny);;
    params.status && (param.status = params.status);
    params.page && (param.page = Number(params.page));
    params.since && (param.since = params.since);
    params.until && (param.until = params.until);
    params.search && (param.search = params.search);
    params.bank_origin && (param.bank_origin = params.bank_origin);
    params.device && (param.device = params.device);
    try {
      if (param.company) {
        await queryClient.fetchQuery(
          queryOptions({
            queryKey: ["paymentsHistory"],
            queryFn: () =>
               getPaymentRequest(params)
                .then((d) => {
                  setPayments(d.data.results, d.data.count);
                })
                .catch(({ response }) => {
                  if (response?.status === 400) {
                    const errorMessage =
                      Object.keys(response.data || {})
                        .join(", ")
                        .concat(
                          ": " + Object.values(response.data || {}).join(", ")
                        ) ||
                      Object.values(response.data || {}).join(", ") ||
                      "Error desconocido";
                    return ErrorToast(errorMessage);
                  }
                }),
            retry: false,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toBack = () => {
    navigate(`../${item}`);
  };
  useEffect(() => {
    handleConsultation();
  }, [params, serviceId, id]);
  const handleSearch = ({ search }: any) => {
    if (search == undefined || search == null) return;

    if (search.length == 0) {
      deleteParams(["search"]);
      return;
    }
    const { page, ...rest } = params;
    setSearchParams({ ...rest, search });
  };
  const handleDelete = () => {
    deleteParams(["since", "until", "device", "bank_origin", "status"]);
    handleConsultation();
  };

  return (
    <div className="animate-fade-up space-y-5">
      <div>
        <FaArrowLeft className="w-6 h-6 cursor-pointer" onClick={toBack} />
        <div className="flex f justify-start items-center gap-4 ">
          <h1 className="text-2xl font-medium text-neutral-700 dark:text-titleDark">
            Historial de Transacciones
          </h1>
        </div>
        {/* <BottonsPayments refresh={handleConsultation} /> */}
        <TemplateTableLayout
          title=""
          bottons={
            <div className="flex items-center mx-2">
              {/* <ValidatePayment reload={handleConsultation} /> */}
              <Button
                variant="ghost"
                className="dark:text-white"
                endContent={<HiArrowPath className="h-6 w-6" />}
                onClick={handleConsultation}
              >
                Recargar
              </Button>
            </div>
          }
          deleteParams={handleDelete}
          search={handleSearch}
          filters={[
            {
              name: "",
              component: <CheckFilterPayments />,
              field: "status",
            },
            {
              name: "Seleccione los dispositivos",
              component: <FilterForSCPaymentMethod />,
              field: "banks",
            },
            {
              name: "Introduce un rango de fecha",
              component: <SinceUntilPayments />,
              field: "since",
            },
            {
              name: "Selecciona los bancos",
              component: <BanksDestinyOriginPayments />,
              field: "banks",
            },
          ]}
        >
          <>
            {params.since && (
              <p className="font-semibold text-xl dark:text-white text-end mt-4">
                {params.since && params.since}
                {params.until && ` – ${params.until}`}
              </p>
            )}
            {count > 0 ? (
              <TablePayment />
            ) : (
              <div className="text-center">
                <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-xl font-semibold text-gray-500">
                  No hay resultado
                </h3>
              </div>
            )}
          </>
        </TemplateTableLayout>
      </div>
    </div>
  );
};
