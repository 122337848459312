import { Card, CardBody, Tooltip } from "@nextui-org/react";
import { FcCurrencyExchange } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { dollarServices } from "../../../../store/dollar/thunks";
import { useAllParams } from "../../../../hooks/useAllParams";
import { DollarParams } from "../../params/dollar/DollarParams";
import { log } from "console";
import { today } from "@internationalized/date";
import { formaterTimeInternalized } from "../../../../libs/GetTimeInternalized";
import { PiCurrencyCircleDollarLight } from "react-icons/pi";
import React from "react";
import moment from "moment";

interface Responsive {
  isMobile?: boolean;
}
export const CardDolar = ({ isMobile }: Responsive) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dollar, isLoading } = useSelector((state: RootState) => state.dollar);
  const { params, setSearchParams } = useAllParams();
  const date = moment().format('DD/MM/YYYY');

  const handleDateDollar = () => {  
    console.log(date)
    const param = new DollarParams();
    param.date = moment().format('YYYY-MM-DD');
    // setSearchParams({ fecha: date });
    dispatch(dollarServices(param));
  };
 React.useEffect(() => {handleDateDollar()}, [])
  return (
    <>
      {!isMobile ? (
        <div className="cursor-pointer" onClick={handleDateDollar}>
          <div className="grid justify-center">
            <div className="flex justify-center items-center space-x-2">
              <span className="font-semibold text-sm text-gray-700">
                Tasa BCV, hoy {date}:
              </span>
            </div>
            <div className="inline-flex items-center justify-center space-x-2">
              <span className="text-primary font-semibold text-xl">
                {dollar && dollar[0]?.amount} Bs
              </span>
            </div>
          </div>
        </div>
      ) : (
        <Tooltip  showArrow={true} content={`Tasa BCV hoy ${date}:`} >
          <div className="flex flex-col items-center justify-center w-full" onClick={handleDateDollar}>
            <span className="text-primary font-semibold">
              {dollar && dollar[0]?.amount} Bs.
            </span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

// {dollar.length > 0 && dollar[0].monto}
