import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  AutocompleteItem,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { WarehouseParams } from "../../../../../params/management/warehouseParams";
import { getWarehouseRequest } from "../../../../../../../store/managements/warehouse/thunks";
import { useParams } from "react-router-dom";
import { GetPaymentMethodCompaniesThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import {
  MethodPayment,
  MethodPaymentCompany,
} from "../../../../../interfaces/PaymentInterfaces";
import { GetServicesCompaniesThunks } from "../../../../../../../store/companies/servicesCompany/thunks";
import { DevicesServiceConfig } from "../../../../../interfaces/DevicesServicesConfig";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { useStoreWarehouseCore } from "../../../../../../../store/managements/warehouse/useStoreWarehouseCore";
import { useStoreSCPaymentMethodCore } from "../../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { ServicesConfigParams } from "../../../../../params/services-configs/ServicesConfigParams";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { Device } from "../../../../../interfaces/DevicesInterface";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { ServicesCompany } from "../../../../../interfaces/ServicesCompany";
import { Warehouse } from "../../../../../interfaces/WarehouseInterfaces";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";

type Props = {
  setData?: (data: any) => void;
  data?: DevicesServiceConfig;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

let schemaDevices = yup.object({
  payment_method_cs: yup.string().required("Este campo es requerido"),
  device: yup.string(),
  address: yup.string(),
  service: yup.string().required("Este campo es requerido"),
});

export const FormServicesConfig = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      resolver: yupResolver(schemaDevices),
    });
    const { id, item } = useParams();
    const { company } = useUserMovilPay();
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    const queryClient = useQueryClient();
    const [warehouse, setWarehouse] = React.useState<Warehouse[]>([]);
    const [scpaymentMethod, setscpaymentMethod] = React.useState<
      MethodPaymentCompany[]
    >([]);
    const [serviceCompany, setServiceCompany] = React.useState<
      ServicesCompany[]
    >([]);
    const [filterPay, setFilterPay] = React.useState<MethodPaymentCompany[]>(
      []
    );
    const option = id ? id : company;
    const handleConsultation = () => {
      const param = new WarehouseParams();
      param.used = data ? "" : "false";
      param.status = "true";
      param.remove_pagination = "true";
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["devicesSCGet"],
          queryFn: () =>
            getWarehouseRequest(param)
              .then((d) => {
                setWarehouse(d.data);
              })
              .catch((err) => console.error(err)),
          retry: false,
        })
      );
    };
    const handleSC = () => {
      const param = new ServicesConfigParams();
      param.is_active = true;
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["serviceCompanysCGet"],
          queryFn: () =>
            GetServicesCompaniesThunks(param, option)
              .then((d) => {
                setServiceCompany(d.data);
              })
              .catch((err) => console.error(err)),
          retry: false,
        })
      );
    };
    const handleSCPM = async (service?: any) => {
      if (service !== undefined) {
        const param = new ServicesConfigParams();
        param.status = 1;
        queryClient.fetchQuery(
          queryOptions({
            queryKey: ["scpaymentsCGet"],
            queryFn: () =>
              GetPaymentMethodCompaniesThunks(param, Number(service))
                .then((d) => {
                  const filter = d.data.filter(
                    (pm: MethodPaymentCompany) =>
                      pm.payment_method === Number(1)
                  );
                  setscpaymentMethod(filter);
                })
                .catch((err) => console.error(err)),
            retry: false,
          })
        );
      }
    };
    // Paso 4 Filtra los metodos de pagos activos y asociados al merchant del dispositivo
    const handleSelectChange = async (e: any) => {
      const filter = await warehouse.filter(
        (device) => device.id === Number(e)
      )[0]?.bank;
      if (filter === null) {
        setFilterPay(scpaymentMethod);
        return;
      } else {
        setFilterPay(
          scpaymentMethod.filter((service) => service.bank === Number(filter))
        );
        return;
      }
    };
    useEffect(() => {
      handleSC();
      handleConsultation();
    }, []);

    useEffect(() => {
      // Paso 1 Consulta de los servicios
      // Paso 2 Consulta de los metodos de pago asociados al merchant del dispositivo
      // Paso 3 Actualiza los valores en los inputs del formulario
      if (data) setValue("service", data?.service.toString() || "");
      const filterIdService = serviceCompany.filter(
        (sc) => sc.service == data?.service
      );
      scpaymentMethod.length == 0 && handleSCPM(filterIdService[0]?.id);
      scpaymentMethod.length > 0 &&
        setValue("payment_method_cs", data?.payment_method_cs.toString() || "");
      scpaymentMethod.length > 0 &&
        handleSelectChange(data?.device.toString() || "");
      setValue("device", data?.device.toString());
      setValue("address", data?.address);
    }, [data, serviceCompany, warehouse, scpaymentMethod]);

    return (
      <>
        <form className="flex flex-col my-4 space-y-2">
          <div className="col-span-full"></div>
          {data ? (
            <div className="flex flex-col gap-2">
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                Servico: {data?.service_name}
              </div>{" "}
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                Dispositivo: {data?.device_serial + " / " + data?.device_mac}
              </div>{" "}
            </div>
          ) : (
            <>
              <Controller
                control={control}
                name="service"
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      items={serviceCompany}
                      isInvalid={!!errors.service}
                      errorMessage={errors.service?.message}
                      placeholder="Selecciona un servicio"
                      variant="bordered"
                      selectedKeys={
                        field.value ? new Set([field.value]) : new Set()
                      } // Show placeholder if field.value is null or empty
                      onSelectionChange={handleConsultation}
                      onChange={(e) => {
                        field.onChange(e.target.value || field.value);
                        handleSCPM(e.target.value);
                      }}
                    >
                      {(service) => (
                        <SelectItem key={service.id}>
                          {service.service_name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
              <div className="my-4">
                <Controller
                  control={control}
                  name="device" // Controla el nombre del fabricante en el formulario
                  render={({ field }) => (
                    <div className="flex items-center">
                      <Autocomplete
                        {...field} // Pasa las propiedades del Controller
                        placeholder="Selecciona un dispositivo"
                        className="max-w"
                        variant="bordered"
                        defaultItems={warehouse}
                        isInvalid={!!errors.device}
                        errorMessage={errors.device?.message}
                        selectedKey={field.value}
                        onSelectionChange={(e) => {
                          field.onChange(e);
                          handleSelectChange(e);
                        }}
                        onChange={(e) => {
                          handleSCPM(e.target.value);
                          handleSelectChange(e.target.value);
                        }}
                      >
                        {(item) => (
                          <AutocompleteItem key={item.id}>
                            {item.serial +
                              " /  " +
                              item.mac +
                              (item.bank != null
                                ? " /  " + item?.bank_name
                                : " /  MOVILPAY")}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    </div>
                  )}
                />
              </div>
            </>
          )}

          <div className="col-span-full">
            <Controller
              control={control}
              name="payment_method_cs"
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    items={filterPay}
                    isDisabled={data ? false : filterPay.length == 0}
                    selectedKeys={
                      field.value ? new Set([field.value]) : new Set()
                    } // Show placeholder if field.value is null or empty
                    isInvalid={!!errors.payment_method_cs}
                    errorMessage={errors.payment_method_cs?.message}
                    placeholder="Seleccione un método de pago"
                    variant="bordered"
                  >
                    {(payment) => (
                      <SelectItem key={payment.id}>
                        {payment.account_number +
                          " - " +
                          payment.phone +
                          " - " +
                          payment.bank_achronym}
                      </SelectItem>
                    )}
                  </Select>
                </>
              )}
            />
          </div>
          <div className="col-span-full">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <Textarea
                  {...field}
                  isInvalid={!!errors.address}
                  errorMessage={errors.address?.message}
                  type="text"
                  autoFocus
                  placeholder="Agregar la dirección donde estará el equipo"
                  variant="bordered"
                />
              )}
            />
          </div>
        </form>
      </>
    );
  }
);

// ) : (
//   <>
//     <div className="flex items-center justify-center">
//       <div className="flex flex-col justify-center rounded-lg bg-gray-50 px-16 py-14">
//         <div className="flex  items-center w-full px-4">
//           {/* <img src="https://i.ibb.co/ck1SGFJ/Group.png" /> */}
//           <MdCrisisAlert className="mx-auto w-1/4 h-1/4" />
//         </div>
//         <h3 className="my-4 text-center text-3xl font-semibold text-gray-700">
//           Atención{" "}
//         </h3>
//         <p className=" text-center font-normal text-gray-600">
//           Debe tener el servico SNP para poder continuar con la
//           configuración del Servicio {item}
//         </p>
//         <Link to={`../`} className="w-full mx-auto text-center">
//           <Button
//             color="primary"
//             className=" mt-10  shadow-lg"
//             radius="full"
//             variant="solid"
//             startContent={<FaArrowUpRightFromSquare />}
//           >
//             Ir a servicio
//           </Button>
//         </Link>
//       </div>
//     </div>
//   </>
// )}
