import {
  Button,
  CircularProgress,
  DatePicker,
  DateValue,
  Divider,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import {
  CompaniesIDThunks,
  CompaniesThunks,
  PostCompaniesThunks,
} from "../../../../../../store/companies/thunks";
import { LoadingToast } from "../../../../../../libs/Notifications";
import { getLocalTimeZone, today } from "@internationalized/date";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { FcSearch } from "react-icons/fc";
import { CompaniesParams } from "../../../../params/companies/companiesParams";
import { CardCompany } from "./CardCompany";
import { resetComponies } from "../../../../../../store/companies/CompaniesSlice";
import { type } from "../../../../components/methodPayments/typeIdentification";
import { FormEditCompany } from "../../company/InfoCompany/FormEditCompany";
import {
  schemaCompany,
  schemaCompanyShort,
} from "../../company/InfoCompany/Validators";
import { useNavigate } from "react-router-dom";

type Props = {
  setCompanyCreate: (data: any) => void;
};

export const FormCreateCompany = () => {
  const { companies, count, isLoading } = useSelector(
    (d: RootState) => d.companies
  );
  const formRef = React.useRef<() => void | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const typeItems = type;
  let defaultDate = today(getLocalTimeZone());
  const [date] = React.useState<DateValue>(defaultDate);
  const [existingCompany, setExistingCompany] = React.useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schemaCompanyShort),
    defaultValues: {
      type: "V",
    },
  });
  const watchOptions = watch("rif");
  const watchTypes = watch("type");
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const newDate = new Date().toISOString().split("T")[0];
    let body = {
      ...data,
      phone: "+" + data.code.concat(data.phone),
      whatsapp_phone: "+" + data.codeWhats.concat(data.whatsapp_phone),
      rif: watchTypes.concat(watchOptions),
      start_date_work: newDate,
      creation_date: newDate,
    };
    PostCompaniesThunks(body)
      .then((results) => {
        success(
          "Datos de la compañia agregados exitosamente! Seguir con el registro."
        );
        const id: number = results.data.id;
        dispatch(CompaniesIDThunks({ id }));
        setTimeout(() => {
          navigate("../paso/2");
        }, 1500);
      })
      .catch(() => {
        error("Hubo un error al registrar el Servicio");
        return;
      });
  };
  const resetForm = () => {
    reset();
    setExistingCompany(false);
    dispatch(resetComponies());
  };

  const handleSearch = () => {
    if (isValid) {
      if (watchOptions && watchOptions.length > 0) {
        const param = new CompaniesParams();
        param.search = watchTypes.concat(watchOptions);
        dispatch(CompaniesThunks(param));
        console.log(count);
        setExistingCompany(count === 0);
      }
    }
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  useEffect(() => {
    resetForm();
  }, []);

  return (
    <>
      <div className="overflow-hidden  mt-3 my-4">
        <div className=" p-6">
          <div className=" ">
            <form
              onSubmit={handleSubmit(handleSearch)}
              className="max-w-xl mx-auto"
            >
              <label> Ingrese la cédula o rif del comercio </label>
              <div className="col-span-full flex items-center justify-center  space-x-1">
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        variant="bordered"
                        items={typeItems}
                        selectedKeys={new Set([field.value])}
                        className="w-24"
                        isInvalid={!!errors.type}
                        errorMessage={errors.type?.message}
                      >
                        {(type) => (
                          <SelectItem key={type.label}>{type.label}</SelectItem>
                        )}
                      </Select>
                      <span></span>
                    </>
                  )}
                />

                <Controller
                  control={control}
                  name="rif"
                  render={({ field }) => (
                    <Input
                      {...field}
                      isInvalid={!!errors.rif}
                      errorMessage={errors.rif?.message}
                      type="text"
                      autoFocus
                      placeholder="Cédula o Rif"
                      variant="bordered"
                    />
                  )}
                />
              </div>
              <div className="flex  w-full my-4">
                {!existingCompany && count === 0 && (
                  <Button
                    size="md"
                    type="submit"
                    color="primary"
                    className="p-3 rounded-full  w-full font-semibold bg-primary"
                  >
                    Siguiente
                  </Button>
                )}
              </div>
            </form>
            {count > 0 && (
              <>
                {isLoading ? (
                  <div className="col-span-full flex justify-center items-center max-w-full my-6 ">
                    <CircularProgress
                      className="my-6"
                      size="lg"
                      color="primary"
                      label="Cargando"
                    />
                  </div>
                ) : (
                  <div className="grid  justify-center gap-4  items-center max-w-full mx-auto mt-6">
                    {companies.map((company) => (
                      <CardCompany key={company.id} companys={company} />
                    ))}
                  </div>
                )}
              </>
            )}
            {existingCompany && count === 0 && (
              <>
                {isLoading ? (
                  <div className="flex justify-center max-w-full self-center">
                    <CircularProgress
                      className="my-6"
                      size="lg"
                      color="primary"
                      label="Cargando"
                    />
                  </div>
                ) : (
                  <>
                    <FormEditCompany onSubmit={onSubmit} ref={formRef} />
                    <div className=" col-span-full  flex justify-center gap-x-2 my-6">
                      <Button
                        onClick={resetForm}
                        color="primary"
                        type="button"
                        size="md"
                        variant="bordered"
                        className=" shadow-lg font-semibold text-base rounded-lg active:scale-95 transition-transform transform"
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        onClick={handleSubmitClick}
                        className=" text-white text-base shadow-lg font-semibold  rounded-lg active:scale-95 transition-transform transform"
                      >
                        Guardar
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
