export class DevicesServicesConfigParams{
   
    ordering?: number|string
    page?: number
    status?:string|boolean
    service_config?:number
    payment_method_cs?: number |string
    company_service?: string;
    company_id?: string;
    company?: string | number;
    is_active?: boolean;
    paage?:number;
    search?:string;
    service?:string;
    remove_pagination?:string;

}