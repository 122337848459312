// @flow
import * as React from "react";
import { schemaParish } from "./Validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import { OcupationCompany } from "../company/CompanyOptions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { StatesThunks } from "../../../../store/location/States/thunks";
import { PaymentParams } from "../../params/payment/payments/paymentParams";
type Props = {
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormParish = React.forwardRef(({ onSubmit }: Props, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const { states } = useSelector((state: RootState) => state.states);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaParish),
  });
  React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
  const handleConsultation = () => {
    const param: PaymentParams = new PaymentParams();
    param.remove_pagination = true;
    dispatch(StatesThunks(param));
  };

  React.useEffect(() => {
    dispatch(handleConsultation);
  }, []);
  return (
    <div>
      <form className="grid gap-3">
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                variant="bordered"
                items={states}
                placeholder="Seleccione el Estado"
                selectedKeys={field.value ? new Set([field.value]) : new Set()}
                isInvalid={!!errors.state}
                errorMessage={errors.state?.message}
              >
                {(type) => (
                  <SelectItem key={type.id} value={type.id}>
                    {type.name}
                  </SelectItem>
                )}
              </Select>
            </>
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              isInvalid={!!errors.name}
              errorMessage={errors.name?.message}
              type="text"
              autoFocus
              placeholder="Nombre de la parroquia"
              variant="bordered"
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              {...field}
              isInvalid={!!errors.description}
              errorMessage={errors.description?.message}
              type="text"
              autoFocus
              placeholder="Agregue una breve descripción"
              variant="bordered"
            />
          )}
        />
      </form>
    </div>
  );
});
