// @flow 
import { useDisclosure, Button } from '@nextui-org/react';
import * as React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { ModalComponent } from '../../../../components/Modal/ModalComponent';
import { FormWarehouse } from './forms/FormWarehouse';
import { ErrorToast, SuccessToast } from '../../../../../../libs/Notifications';
import { PostWarehouse } from '../../../../../../store/managements/warehouse/thunks';

type Props = {
  e?: any
  reload: () => void
};

export const AddToWarehouse = ({ e,reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = React.useState(e);

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {

    let body;
    if (formData.bank && formData.bank!=0) {
      body= formData
    } else{
      body = { ...formData, bank: null }
    }

    console.log("Submitted data", body);

    PostWarehouse(body)
    .then((result) => {
      SuccessToast("Producto agregado correctamente a inventario");
      
      onClose();
      reload();
    })
    .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
    
      return;
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current()
    }
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Producto
      </Button>

      <ModalComponent
        header={"Registrar nuevo producto"}
        body={<FormWarehouse  setData={setData} onSubmit={handleFormSubmit} ref={formRef} />} 
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

