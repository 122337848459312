// @flow
import { Button, useDisclosure } from "@nextui-org/react";
import * as React from "react";
import { useRef } from "react";
import { ErrorToast, LoadingToast } from "../../../../libs/Notifications";
import { IoIosAdd } from "react-icons/io";
import { ModalComponent } from "../Modal/ModalComponent";
import { FormParish } from "./FormParish";
import { FormStates } from "./FormStates";
import { FormSector } from "./FormSector";
import { PostStateThunks } from "../../../../store/location/States/thunks";
import {
  PostSectorThunks,
  SectorsThunks,
} from "../../../../store/location/Sector/thunks";
import { PostParishThunks } from "../../../../store/location/Parish/thunks";
import { LocationParams } from "../../params/locations/LocationParams";
import { AppDispatch } from "../../../../store/store";
import { useDispatch } from "react-redux";
type Props = {
  data?: any;
  reload?: (e?: any) => void;
};

export const Parish = ({ reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    console.log("update data", formData);

    PostParishThunks(formData)
      .then((result) => {
        success("Parroquia agregado correctamente");
        onClose();
        reload && reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <div className="relative px-4 py-5 sm:px-6">
        <Button
          color="primary"
          onPress={onOpen}
          endContent={<IoIosAdd className="h-7 w-7" />}
        >
          Agregar parroquia
        </Button>
      </div>
      <ModalComponent
        header={`Parroquias`}
        body={<FormParish onSubmit={handleFormSubmit} ref={formRef} />}
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
export const Sector = ({ reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    PostSectorThunks(formData)
      .then((result) => {
        success("Sector  agregado correctamente");
        onClose();
        reload && reload();
        // const param: LocationParams = new LocationParams();
        // param.remove_pagination = true;
        // param.parroquia = formData.parroquia;
        // dispatch(SectorsThunks(param));
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <div className="relative px-4 py-2 sm:px-6">
        <Button
          color="primary"
          onPress={onOpen}
          endContent={<IoIosAdd className="h-7 w-7" />}
        >
          Agregar sector
        </Button>
      </div>
      <ModalComponent
        header={`Sectores`}
        body={<FormSector onSubmit={handleFormSubmit} ref={formRef} />}
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
export const States = ({ reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();

    PostStateThunks(formData)
      .then((result) => {
        success("Estado  agregado correctamente");
        onClose();
        reload && reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <div className="relative px-4 py-5 sm:px-6">
        <Button
          size="sm"
          color="primary"
          className="absolute top-0 right-0 "
          radius="full"
          variant="ghost"
          isIconOnly
          onPress={onOpen}
        >
          <IoIosAdd className="h-7 w-7" />
        </Button>
      </div>
      <ModalComponent
        header={`Estados`}
        body={<FormStates onSubmit={handleFormSubmit} ref={formRef} />}
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
