// @flow
import * as React from "react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { ServicesThunks } from "../../../../../../store/services/thunks";
import { Services } from "../../../../interfaces/ServicesInterfaces";
import {
  Badge,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PLayouts } from "../../../../layout/PLayouts";
import { CustomCheckbox } from "../../company/services/SelectedServices";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CompaniesIDThunks } from "../../../../../../store/companies/thunks";
import { LoadingToast } from "../../../../../../libs/Notifications";
import { CardCompany } from "./CardCompany";
import { Company } from "../../../../interfaces/CompaniesInterfaces";
import { PostServicesCompaniesThunks } from "../../../../../../store/companies/servicesCompany/thunks";
import { MdCrisisAlert } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { usePermissionsMovilPay } from "../../../../../../hooks/useUserMovilPay";
import { useStorePermissions } from "../../../../../../store/permission/useStorePermission";

type Props = {
  services?: Services[];
  setData?: (data: any) => void;
  titleButton?: string;
};
interface FormValues {
  options: { [key: string]: any };
}

export const ServicesCompany = () => {
  const permissionCompany = usePermissionsMovilPay();
  const { setPermissions } = useStorePermissions((state) => state);
  React.useEffect(() => {
        setPermissions(permissionCompany);
  }, [permissionCompany]);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedServices, setSelectedServices] = React.useState<Services[]>(
    []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const { companies } = useSelector((state: RootState) => state.companyID);
  const { services, count } = useSelector((state: RootState) => state.services);
  const [idCompany, setIdCompany] = React.useState<any>();
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      options: services?.reduce(
        (acc, service) => ({ ...acc, [service.id]: service }),
        {}
      ),
    },
  });
  // Manejo del envío del formulario
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { success, error } = LoadingToast();
    const arr: any[] = [];
    selectedServices.map((service) => arr.push({ service: service.id }));
    PostServicesCompaniesThunks(arr, idCompany)
      .then((result) => {
        setIsLoading(true);
        success(
          "Servicios agregados exitosamente!"
        );
        dispatch(
          CompaniesIDThunks({
            id: idCompany,
          })
        );
        sessionStorage.clear();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
    // const selected = services.filter((service) => data.options[service.id]);
    // setSelectedServices(selected);
  };

  // Actualizamos el estado de los servicios seleccionados cuando cambian los valores
  React.useEffect(() => {
    console.log(companies);
    if (companies) {
      setIdCompany(companies?.id);
      dispatch(ServicesThunks());
    }
  }, [companies]);
  // React.useEffect(() => {
  //   // dispatch(CompaniesIDThunks({id: idCompany }));

  // }, [idCompany]);

  const handleCheck = (service: Services, checked: boolean) => {
    setSelectedServices((prevSelectedServices: any[]) => {
      if (checked) {
        // Add service to selectedServices if checked
        return [...prevSelectedServices, service];
      } else {
        // Remove service from selectedServices if unchecked
        return prevSelectedServices.filter((s) => s.id !== service.id);
      }
    });
  };

  return (
    <>
      {companies ? (
        isLoading ? (
          <>
            {" "}
            <div className="flex justify-center items-center max-w-full">
              {companies && (
                <CardCompany key={companies.id} companys={companies} />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="grid space-y-4  justify-center w-full">
              <div className="flex justify-between items-center pt-4">
                <PLayouts message="Seleccion los servicos " />
                {selectedServices && selectedServices.length > 0 && (
                  <ShoppingService services={selectedServices} />
                )}
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid space-y-4"
              >
                <CustomCheckbox
                  items={count > 0 ? services : []}
                  name="options"
                  control={control}
                  fnCheck={handleCheck}
                />
                <div className="flex justify-center gap-x-2 my-6">
                  {selectedServices && selectedServices.length > 0 && (
                    <Button
                      color="primary"
                      size="sm"
                      type="submit"
                      className=" text-white text-base shadow-lg font-semibold  rounded-lg active:scale-95 transition-transform transform"
                    >
                      Guardar
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </>
        )
      ) : (
        <>
          <div className="flex items-center justify-center">
            <div className="flex flex-col justify-center rounded-lg bg-gray-50 px-16 py-14">
              <div className="flex  items-center w-full px-4">
                {/* <img src="https:i.ibb.co/ck1SGFJ/Group.png" /> */}
                <MdCrisisAlert className="mx-auto w-1/4 h-1/4" />
              </div>
              <h3 className="my-4 text-center text-3xl font-semibold text-gray-700">
                ¡Atención!
              </h3>
              <p className=" text-center font-normal text-gray-600">
                No se ha detectado un registro reciente, por favor ir al paso 1
                y verificar que el registro se haya realizado de manera
                exitosamente.
              </p>
              <div className="flex justify-center">
                <Button
                  color="primary"
                  className=" mt-10  shadow-lg"
                  radius="full"
                  variant="solid"
                  startContent={<FaArrowUpRightFromSquare />}
                >
                  <Link to={`../paso/1`} className="w-full mx-auto text-center">
                    IR A PASO 1
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ShoppingService = ({ services }: Props) => {
  const count = services?.length || 0;
  return (
    <Popover backdrop="blur" showArrow placement="bottom">
      <Badge color="danger" content={count} shape="circle">
        <PopoverTrigger>
          <Button isIconOnly radius="full" variant="light">
            <RiShoppingCart2Fill size={30} />
          </Button>
        </PopoverTrigger>
      </Badge>
      <PopoverContent className="p-1">
        <Content services={services} />
      </PopoverContent>
    </Popover>
  );
};

const Content = ({ services }: Props) => {
  return (
    <>
      <div className="px-1 py-2 max-w-full">
        <div className="text-base text-center font-bold">
          Servicios agregados
        </div>
        <div className="text-tiny">
          <div role="list" className="p-6 divide-y divide-slate-200  ">
            {services?.map((service) => (
              <li
                key={service.id}
                className="flex py-4 first:pt-0 last:pb-0 p-6 "
              >
                <div>
                  <IoIosCheckmarkCircle className="h-10 w-10 rounded-full text-primary opacity-80" />
                </div>
                <div className="ml-3 overflow-hidden">
                  <p className="text-sm font-medium text-slate-900">
                    {service.name}
                  </p>
                  <p className="text-sm text-slate-500 truncate">
                    {service.description}
                  </p>
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
